import React from "react";
import "./ScanIdScreen.scss";

import Scan from "../Images/icon-Scan-card.png";
import Arrow from "../Images/arrow.png";
import VenueNow from "../Images/logo-rectangle-venuenow.png";

function ScanIdScreen({ setIdData }) {
  const onBlur = (e) => {
    window.setTimeout(() => e.target.focus(), 0);
  };

  return (
    <div className="idScan__base">
      <div className="idScan__title">
        <h1 className="idScan__title__text">
          Scan your ID card using the reader.
        </h1>
        <input
          className="idScan__image__input"
          autoFocus
          type="text"
          onBlur={onBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setIdData(e.target.value);
            }
          }}
        />
      </div>
      <div className="idScan__image">
        <img className="idScan__image__logo" src={Arrow} alt="Arrow" />
        <img className="idScan__image__logo" src={Scan} alt="Scan Card" />
      </div>

      <div className="idScan__footer">
        <img className="idScan__footer__logo" src={VenueNow} alt="Welcome" />
      </div>
    </div>
  );
}

export default ScanIdScreen;
