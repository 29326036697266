import { React } from "react";
import "./Homescreen.scss";

import Logo from "../Images/icon-Container.png";
// import Ad1 from "../Images/advert1.jpg";
// import Ad2 from "../Images/advert2.jpg";
import VenueNow from "../Images/logo-rectangle-venuenow.png";

// const imageArray = [Logo, Ad2, Ad1];

function Homescreen({ homeScreenClicked }) {
  // ------ Ads for HomeScreen ------- //

  // const [count, setCount] = useState(0);

  // useEffect(() => {
  //   const timerId = setInterval(() => {
  //     setCount((count) => count + 1);
  //   }, 4000);

  //   return () => clearInterval(timerId);
  // }, []);

  // const image = imageArray[count % imageArray.length];

  return (
    <div className="home__base" onClick={homeScreenClicked}>
      <div className="home__title">
        <h1 className="home__title__text">Welcome, tap anywhere to begin.</h1>
      </div>
      <div className="home__image">
        <img className="home__image__logo" src={Logo} alt="Welcome" />
      </div>
      <div className="home__footer">
        <img className="home__footer__logo" src={VenueNow} alt="Welcome" />
      </div>
    </div>
  );
}

export default Homescreen;
