import React from "react";
import "./ScanContainerScreen.scss";
import VenueNow from "../Images/logo-rectangle-venuenow.png";

import Logo from "../Images/icon-Container.png";
import Arrow from "../Images/arrow.png";

function ScanContainerScreen({ setPackageData, setActivePage, finish }) {
  const onBlur = (e) => {
    window.setTimeout(() => e.target.focus(), 0);
  };

  return (
    <div className="scanPackage__base" onClick={finish}>
      <div className="scanPackage__title">
        <h1 className="scanPackage__title__text">
          Scan packages using the reader. Tap anywhere to finish.
        </h1>
        <input
          className="packageScan__image__input"
          autoFocus
          type="text"
          onBlur={onBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setPackageData(e.target.value);
              setActivePage();
            }
          }}
        />
      </div>
      <div className="scanPackage__image">
        <img className="scanPackage__image__logo" src={Logo} alt="Welcome" />
        <img className="scanPackage__image__logo" src={Arrow} alt="Arrow" />
      </div>
      <div className="scanPackage__footer">
        <img
          className="scanPackage__footer__logo"
          src={VenueNow}
          alt="Welcome"
        />
      </div>
    </div>
  );
}

export default ScanContainerScreen;
